import ProjectRendererData from '../../Components/ProjectRenderer/ProjectRendererData'
import { Footer } from '../../Components/Footer/Footer'
import { useParams } from 'react-router-dom'

export function ProjectDetails() {
  const { id } = useParams<string>()

  if (!id) return <div>404</div>

  return (
    <div className='Details'>
      <section className='workinfo-container'>
        <ProjectRendererData id={id} />
      </section>
      <Footer />
    </div>
  )
}

export default ProjectDetails
