import { Title } from '../../Components/Title/Title'
import { Description } from '../../Components/DescriptionBlock/Description'
import { Footer } from '../../Components/Footer/Footer'

export function Home(history: any) {
  return (
    <div className='Home'>
      <Title />
      <Description />
      <Footer />
    </div>
  )
}
export default Home
