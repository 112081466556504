import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { IProjectRendererProps } from '../../generics/interfaces'
import { languages } from '../../generics/utils'
import styles from './project.module.scss'
import Button from '../Button/Button'

export function ProjectRenderer(props: IProjectRendererProps) {
  const { project } = props
  return (
    <div className={styles.container}>
      <div className={styles.containerDescription}>
        <h2 className={styles.name}>
          <Link to={`/details/${project.id}`}>{project.name}</Link>
        </h2>
        <p>{project.companyName}</p>

        <div className={styles.description}>
          {project.description}
          <span>{project.span}</span>
        </div>

        {project.isPrivate && <p className={styles.private}>Project code is private</p>}

        <div className={styles.buttons}>
          <Button url={project.liveDemo} btnText='Check Live Demo' />
          <Button url={project.readMore} btnText='Read More' />
          <Button url={project.github} btnText='Check Code' />
        </div>
      </div>
      <div className={styles.thumbnail}>
        <img src={project.src} alt={project.name} />
        <div className={styles.thumbnailBox}></div>
        <div className={styles.skills}>
          {project.languages &&
            Object.values(project.languages).map((language: any) => {
              return (
                <i
                  data-tip={languages[language as keyof typeof languages].name}
                  className={languages[language as keyof typeof languages].class}
                  key={languages[language as keyof typeof languages].name}
                ></i>
              )
            })}
        </div>
      </div>
      <Tooltip />
    </div>
  )
}

export default ProjectRenderer
