import React, { Component } from 'react'
import styles from './description.module.scss'

export class Description extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.indent}>
          <hr />
        </div>
        <div className={styles.description}>
          <p>
            I am a Project Manager, Freelance FullStack Developer, Linux System Admin and UI/UX
            designer with 10 years of experience in the IT industry. I am looking for a full time
            development position to pursue a stable technical role.
          </p>
          <h4 className={styles.title}>Here is a short description of my past activities:</h4>
          <ul>
            <li>
              I have been mastering Javascript/Typescript and developing projects in React or
              Angular. I have also been focusing on creating better user experiences with React.
            </li>
            <li>
              I am currently working as Senior Frontend Developer for JTI and as Assistant Trainer
              for NewTechAcademy, a coding bootcamp in Romania.
            </li>
            <li>
              I have developed strong soft skills throughout my career, such as: Leadership: I have
              led and mentored teams of developers, designers and testers, ensuring quality and
              timely delivery of projects.
            </li>
            <li>
              Communication: I have communicated effectively with clients, stakeholders and
              colleagues, using various tools and platforms such as Slack, Zoom and Jira.
            </li>
            <li>
              Problem-solving: I have applied analytical thinking and creativity to solve complex
              technical problems and troubleshoot issues.
            </li>
            <li>
              Adaptability: I have adapted to changing requirements, technologies and environments,
              learning new skills and tools quickly.
            </li>
          </ul>
          <p>
            I am passionate about coding and eager to learn more in a dedicated environment with a
            driven team.
          </p>
        </div>
      </div>
    )
  }
}

export default Description
