import { Link } from 'react-router-dom'
import { languages } from '../../generics/utils'
import styles from './project.module.scss'
import projects from '../../generics/projects.json'
import { useMemo } from 'react'
import { ProjectFeature } from '../../generics/interfaces'
import CodeHighlighter from '../CodeHighlighter/CodeHighlighter'
import Txt from '../Txt/Txt'

const ProjectRendererData = (projectID: { id: string }) => {
  const currentProject = useMemo(
    () => projects.projects.find((project: any) => project.id === projectID.id),
    [projectID.id],
  )
  if (!currentProject) {
    return <div>Project not found</div>
  }

  return (
    <main className={styles.projectContainer}>
      <section className={styles.hero}>
        <section>
          <div className={styles.title}>
            <Link to='/work'>
              <i
                className='fas fa-long-arrow-alt-left backArrow'
                style={{ fontSize: '5.5rem' }}
              ></i>
            </Link>
            <h1>{currentProject.name}</h1>
          </div>

          <div className={styles.projectTech}>
            <div className={styles.projectSkills}>
              <div className={styles.skills}>
                {currentProject.languages &&
                  Object.values(currentProject.languages).map((language: any) => {
                    return (
                      <i
                        data-tip={languages[language as keyof typeof languages].name}
                        className={languages[language as keyof typeof languages].class}
                        key={languages[language as keyof typeof languages].name}
                      ></i>
                    )
                  })}
              </div>
              <div className={styles.details}>
                <p>
                  <span>Author:</span> Mircea Dumitrescu
                </p>
                <p>
                  <span>Github Project:</span> {currentProject.isPrivate ? 'Private' : 'Public'}
                </p>
                <p>
                  <span>Employer:</span> {currentProject.companyName}
                </p>
                <p>
                  <span>Published:</span> {currentProject.datePublished}
                </p>
              </div>
            </div>
          </div>
        </section>
        <aside className={styles.heroImage}>
          <img src={currentProject.src} alt={currentProject.name} />
        </aside>
      </section>

      <div className={styles.projectArticle}>
        <Txt title='Project Description' variant='h2'>
          {currentProject.introduction}
        </Txt>
        <Txt title='My Role' variant='h3'>
          {currentProject.role}
        </Txt>
        <Txt title='Conclusions' variant='h3'>
          {currentProject.conclusion}
        </Txt>
        <Txt title='Project Features' variant='h2'>
          {currentProject.features.map((feature: ProjectFeature, index: any) => (
            <div key={index}>
              <h3>{feature.title}</h3>
              <p>{feature.p1}</p>
              <p>{feature.p2}</p>
              <CodeHighlighter codeSyntax={feature.code} />
            </div>
          ))}
        </Txt>
        <h2>Featured Screenshots</h2>
        <div className={styles.featureImage}>
          {currentProject.images.map((image: any) => (
            <a href={image.image} target='_blank' rel='noreferrer' key={image.id}>
              <img src={image.image} alt={image.title} />
              <Txt variant='h3'>{image.title}</Txt>
            </a>
          ))}
        </div>
      </div>
    </main>
  )
}
export default ProjectRendererData
