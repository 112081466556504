import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './Pages/Homepage/Home'
import Work from './Pages/WorkPage/WorkPage'
import { ProjectDetails } from './Pages/ProjectDetails/ProjectDetails'
import PageNotFound from './Pages/404-page/404-page'

const PublicRoutes = () => {
  return (
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route path='/work' element={<Work />} />
      <Route path='/details/:id' element={<ProjectDetails />} />
      <Route element={<PageNotFound />} />
    </Routes>
  )
}

export default PublicRoutes
