import styles from './txt.module.scss'

type Props = {
  title?: string
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'span' | 'div' | 'p'
  children: string | React.ReactElement | React.ReactElement[]
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'
}

const Txt = ({
  title,
  as: Component = 'div',
  children,
  variant = 'h1',
}: Props): React.ReactElement => {
  return (
    <Component className={styles.txt}>
      {title && <h2 className={`${styles[variant] || ''}`}>{title}</h2>}
      {children}
    </Component>
  )
}

export default Txt
