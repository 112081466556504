import React from 'react'

const PageNotFound = () => {
  setTimeout(() => {
    window.location.href = '/'
  }, 5000)

  return (
    <div id='wrapper'>
      <div id='info'>
        <h3>This page could not be found</h3>
      </div>
      <img src='https://i.imgur.com/qIufhof.png' alt='page not found' />
    </div>
  )
}

export default PageNotFound
