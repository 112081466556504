export const languages = {
  html: {
    name: 'HTML',
    class: 'fab fa-html5',
  },
  css: {
    name: 'CSS',
    class: 'fab fa-css3-alt',
  },
  js: {
    name: 'JavaScript',
    class: 'fab fa-js-square',
  },
  react: {
    name: 'React',
    class: 'fab fa-react',
  },
  node: {
    name: 'Node.js',
    class: 'fab fa-node',
  },
  express: {
    name: 'Express',
    class: 'fab fa-node',
  },
  mongodb: {
    name: 'MongoDB',
    class: 'fas fa-database',
  },
  mysql: {
    name: 'MySQL',
    class: 'fas fa-database',
  },
  git: {
    name: 'Git',
    class: 'fab fa-git-alt',
  },
  angular: {
    name: 'Angular',
    class: 'fab fa-angular',
  },
  bootstrap: {
    name: 'Bootstrap',
    class: 'fab fa-bootstrap',
  },
  sass: {
    name: 'Sass',
    class: 'fab fa-sass',
  },
  firebase: {
    name: 'Firebase',
    class: 'fab fa-firefox',
  },
  tailwind: {
    name: 'Tailwind',
    class: 'fab fa-tailwind',
  },
}
