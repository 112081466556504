import SyntaxHighlighter from 'react-syntax-highlighter'
import { dracula } from 'react-syntax-highlighter/dist/cjs/styles/hljs'

const CodeHighlighter = (code: { codeSyntax: string }) => {
  return (
    <SyntaxHighlighter
      language='javascript'
      showInlineLineNumbers={true}
      customStyle={{
        padding: '2rem',
        borderRadius: '0.5rem',
        whiteSpace: 'pre-wrap',
        letterSpacing: '0.5px',
        lineHeight: '1.5',
        marginBottom: '5rem',
      }}
      style={dracula}
    >
      {code.codeSyntax}
    </SyntaxHighlighter>
  )
}

export default CodeHighlighter
