import ProjectRenderer from '../../Components/ProjectRenderer/ProjectRenderer'
import Title from '../../Components/Title/Title'
import Footer from '../../Components/Footer/Footer'
import projects from '../../generics/projects.json'
import GithubMark from '../../assets/github-mark.png'
import styles from './workpage.module.scss'
import { motion } from 'framer-motion'
import { Project } from '../../generics/interfaces'

function WorkPage() {
  const GITHUB_URL = 'https://github.com/MirceaDumitrescu'

  const sortedProjects = Object.values(
    projects.projects.sort((a: Project, b: Project) => Number(b.id) - Number(a.id)),
  )

  return (
    <div>
      <Title />
      <section className={styles.container}>
        <div className={styles.button}>
          <div>
            <a
              href={GITHUB_URL}
              rel='noopener noreferrer'
              target='_blank'
              className={styles.buttonLogo}
            >
              <motion.img
                src={GithubMark}
                alt='Github Logo'
                animate={{ rotate: 180 }}
                transition={{
                  repeat: Infinity,
                  repeatType: 'reverse',
                  duration: 2,
                }}
              />
              <span className={styles.buttonMessage}>Check my other repositories on github</span>
            </a>
          </div>
        </div>

        {sortedProjects.map((project: Project) => (
          <ProjectRenderer key={project.id} project={project} />
        ))}
      </section>
      <Footer />
    </div>
  )
}

export default WorkPage
