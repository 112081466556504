import { BrowserRouter as Router } from 'react-router-dom'
import PublicRoutes from './Router'
import Navbar from './Components/Navbar/Navbar'
import './App.scss'

function App() {
  return (
    <Router>
      <Navbar />
      <PublicRoutes />
    </Router>
  )
}
export default App
