import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './navbar.module.scss'

export function Navbar() {
  const handleShowHamburger = () => {
    const hamburger = document.querySelector(`.${styles.hamburgerLinks}`)
    if (!hamburger) return null
    hamburger.classList.toggle(`${styles.showHamburger}`)
  }

  const location = useLocation()
  const isHomePage = useMemo(() => location.pathname === '/', [location.pathname])
  const isWorkPage = useMemo(() => location.pathname === '/work', [location.pathname])

  console.log('isHomePage: ', isHomePage)
  console.log('isWorkPage: ', isWorkPage)

  return (
    <>
      <ul className={styles.navbar}>
        <li className={styles.link}>
          <Link to={'/'}>Home</Link>
          <hr className={isHomePage ? styles.activeLocation : styles.inactiveLocation} />
        </li>
        <li className={styles.link}>
          <Link to={'/work'}>Work</Link>
          <hr className={isWorkPage ? styles.activeLocation : styles.inactiveLocation} />
        </li>
        <li className={styles.resume}>
          <a
            href='https://drive.google.com/file/d/1Md_qAqx-iN7Ktb5wjtXGUd4ie-tB61k7/view?usp=sharing'
            target='_blank rel='
            rel='noopener noreferrer'
          >
            <i className='fas fa-file-download'></i>
          </a>
        </li>
      </ul>
      {/* HAMBURGER HTML */}
      <section className={styles.hamburgerHeader}>
        <div className={styles.hamburgerNavTitle}>
          <h1>dumitrescumircea.ro</h1>
        </div>
        <div className={styles.hamburgerNav}>
          <i className='fas fa-bars' onClick={handleShowHamburger}></i>
        </div>
      </section>
      <div className={styles.hamburgerContainer}>
        <ul className={styles.hamburgerLinks}>
          <li className={styles.hamburgerLink}>
            <Link to={'/'} onClick={handleShowHamburger}>
              Home
            </Link>
          </li>
          <li className={styles.hamburgerLink}>
            <Link to={'/Work'} onClick={handleShowHamburger}>
              Work
            </Link>
          </li>
          <li className={styles.resume}>
            <a
              href='https://drive.google.com/file/d/1Md_qAqx-iN7Ktb5wjtXGUd4ie-tB61k7/view?usp=sharing'
              target='_blank rel='
              rel='noopener noreferrer'
            >
              Resume
            </a>
            <i className='fas fa-file-download'></i>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Navbar
