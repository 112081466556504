import { Component } from 'react'
import { Tooltip } from 'react-tooltip'
import styles from './title.module.scss'

export class Title extends Component {
  render() {
    return (
      <div>
        <div className={styles.container}>
          <div className={styles.avatar}>
            <div className={styles.avatarColor}></div>
            <div className={styles.avatarImage}></div>
          </div>
          <div className={styles.name}>
            <h1>MIRCEA DUMITRESCU</h1>
            <hr />
            <h4 className={styles.jobTitle}>
              Senior Frontend Developer &nbsp;
              <a href='https://www.jti.com/' target='_blank' rel='noreferrer noopener'>
                @Japan Tobacco International
              </a>
            </h4>
            <div className={styles.skills}>
              <i data-tip='CSS' className='fab fa-css3-alt'></i>
              <i data-tip='HTML' className='fab fa-html5'></i>
              <i data-tip='JavaScript' className='fab fa-js-square'></i>
              <i data-tip='Python' className='fab fa-python'></i>
              <i data-tip='React' className='fab fa-react'></i>
              <i data-tip='Angular' className='fab fa-angular'></i>
              <i data-tip='NodeJs' className='fab fa-node-js'></i>
              <i data-tip='Boostrap' className='fab fa-bootstrap'></i>
              <i data-tip='SASS' className='fab fa-sass'></i>
              <i data-tip='Jenkins' className='fab fa-jenkins'></i>
              <i data-tip='Docker' className='fab fa-docker'></i>
            </div>
          </div>
        </div>
        <Tooltip />
      </div>
    )
  }
}

export default Title
