import React, { Component } from 'react'
import styles from './footer.module.scss'

export class Footer extends Component {
  render() {
    return (
      <div className={styles.footer}>
        <p>Ⓒ Dumitrescu Mircea 2021</p>
        <div className={styles.links}>
          <a
            href='https://www.linkedin.com/in/mircea-dumitrescu-8581399a/'
            rel='noopener noreferrer'
            target='_blank'
          >
            <i className='fab fa-linkedin'></i>
          </a>
          <a href='https://github.com/MirceaDumitrescu/' rel='noopener noreferrer' target='_blank'>
            <i className='fab fa-github-square'></i>
          </a>
          <a href='https://www.facebook.com/mirceagab/' rel='noopener noreferrer' target='_blank'>
            <i className='fab fa-facebook-square'></i>
          </a>
        </div>
      </div>
    )
  }
}

export default Footer
