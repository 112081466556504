interface Props {
  url: string
  btnText: string
}

const Button = (props: Props) => {
  const { url, btnText } = props
  return (
    <a href={url} target='_blank' rel='noreferrer noopener'>
      {url && (
        <button type='button' className='btn btn-primary'>
          {btnText}
        </button>
      )}
    </a>
  )
}

export default Button
